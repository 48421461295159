:root {
  --bs-primary: #007f00 !important;
  --bs-btn-disabled-bg: #007f00 !important;
}

body {
  margin: 0;
  font-weight: 400 !important;
  font-size: 1em !important;
  --bs-bg-opacity: 1;
  background-color: #fafafa !important;
}
@media screen and (max-width: 768px){
  body {
    font-size: .7rem !important;
  }
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}
/*
Overrides
*/
.btn-primary {
  --bs-btn-bg: #007f00 !important;
  --bs-btn-border-color: #007f00 !important;
  --bs-btn-active-shadow: none !important;
  --bs-btn-active-bg: #004f00 !important;
  --bs-btn-active-border-color: #004f00 !important;
  --bs-btn-hover-bg: #004f00 !important;
  --bs-btn-hover-border-color: #004f00 !important;
  --bs-btn-focus-shadow-rgb: #004f00 !important;
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: #007f00 !important;
  border-color: #007f00 !important;
}

.form-control:focus {
  border-color: #007f00 !important;
  box-shadow: none !important;
}
