$dark-primary: #006600;
$primary: #007f00;
$secondary: #e4f1e5;
$white: #ffffff;
$black: #000000;
$vessel: #2b47e6;
$pipeline: #ff6600;
$draft: #616670;

.loginContainer {
  display: grid;
  grid-template-columns: 1.25fr 2fr;
  height: 100%;
  border-top: 0.5px solid #d3d3d3;
}
.signInSection {
  background-color: $white;
  border-left: 8px solid $primary;
}
.signInContainer {
  margin-left: 89px;
  margin-right: 180px;
  margin-top: 20%;
}
.welcomeTitle {
  font-size: 28px;
  line-height: 48px;
  color: $dark-primary;
  display: block;
  margin-bottom: 25px;
}
.intro {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  display: block;
  margin-bottom: 48px;
}
.signInButton {
  width: 100%;
  background-color: $primary !important;
  border-radius: 5px;
  border: none;
}

.signInButton:hover,
.signInButton:focus,
.signInButton:active {
  background-color: $dark-primary !important;
  box-shadow: none !important;
}
.noAccount {
  display: flex;
  margin-bottom: 70px;
}
.contactAdminLink {
  color: $primary;
  text-decoration: underline;
  font-weight: bold;
}
.landingContainer {
  overflow: hidden;
  background-size: cover;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .loginContainer {
    grid-template-columns: auto !important;
  }
  .signInContainer {
    margin-left: 68px;
    margin-right: 90px;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .landingContainer {
    border-left: 8px solid #007f00 !important;
  }
}
.wrapper {
  position: relative;
    width: 100%;
    /* max-width: 1280px; */
    padding: 0 20px;
    /* margin: 0 auto; */
    height: 100vh;
}
.login-container {
  background-image: url(../../public/images/CastrolLogin.jpg);
  background-size: cover;
  background-position: top;
  margin: auto auto 40px auto;
  //width: 100%;
  padding: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
}
.login-container::after{
    content: "";
    display: table;
    clear: both;
}
.login-container h1 {
  color: white;
}
.login-form-container {
  max-width: 300px;
  background-color: white;
  margin-bottom: 20px;
}
.login-form-content {
  padding: 20px;
  overflow: auto;
}
.login-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.login-button {
  font-weight: bold;
  width: calc(100% - 7px);
  box-sizing: border-box;
}
.visitorsignInButton {
  width: 100%;
  color: $primary !important;
  background-color: $white !important;
  border: 1px solid $primary !important;
  border-radius: 5px;
  border: none;
}
@media screen and (max-width: 576px) {
  .login-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .login-button {
    font-weight: bold;
    width: calc(100% - 7px);
    box-sizing: border-box;
  }
}