.sch-drpdwn-toggle {
  position: relative;
  text-align: start;
  // border-bottom: 1px solid #ededed !important;
  // &:hover,
  // &:active,
  // &:focus {
  //   border-top: 0;
  //   border-left: 0;
  //   border-right: 0;
  //   border-bottom: 1px solid #ededed !important;
  // }
}
.sch-drpdwn-toggle svg {
  position: absolute;
  margin-top: 0;
  right: 10px;
  color: #007f00 !important;
}
// .dropdown-menu-sm .dropdown-item {
//   line-height: 0.846154 !important;
// }
// .dropdown-menu {
//   --bs-dropdown-border-width: 1px !important;
//   --bs-dropdown-divider-bg: rgba(0, 0, 0, 0.175) !important;
// }