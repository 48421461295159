$dark-primary: #006600;
$primary: #007f00;
$secondary: #e4f1e5;
$white: #ffffff;
$black: #000000;
$vessel: #2b47e6;
$pipeline: #ff6600;
$draft: #616670;
.form-container {
  padding: 8px;
}

.App {
  display: flex;
  flex-direction: column;
  background: $white;
  margin: 2vh;
  padding: 15px;
}

.main-search-div {
  width: 95%;
  height: 70px;
  background-color: $white;
  margin: 2% 0 2% 1%;
  padding: 17px 0;
}

.search-dropdown-toggle {
  background-color: $white !important;
  border-bottom: 1px solid gray !important;
  border-radius: 0px !important;
  width: 100% !important;
  text-align: left !important;
}

.search-dropdown-menu-container {
  width: 90% !important;
  margin-top: 5px !important;
  border-radius: 0px !important;
}

.search-button {
  border-radius: 50px;
  width: 78px;
  text-align: center;
}
.clear-button {
  color: $black;
  border-radius: 50px;
  background-color: $white !important;
  border-color: $primary !important;
  width: 78px;
  text-align: center;
}
.clear-button:hover {
  color: $black;
}
.documentidcell {
  width: 8em !important;
}
.pagescell {
  width: 2em !important;
}
.table-main-class {
}
@media (max-width: 1400px) {
  .table thead th {
    font-size: 10px !important;
  }
  .table tbody tr td {
    font-size: 10px !important;
  }
  .invDateCell{
    width: 60px !important;
  } 
  .invDateCell {
    width: 60px !important;
  }
}
@media (min-width: 1400px) {
  .invDateCell{
    width: 85px !important;
  } 
  .invDateCell {
    width: 85px !important;
  }
}
.table > thead {
  vertical-align: middle !important;
}
.table > :not(caption) > * > * {
  padding: 5px 13px !important;
}
// .react-table-sort svg{
//     display: none;
// }
.sch-drpdwn-toggle {
  position: relative;
  text-align: start;
  // border-bottom: 1px solid #ededed !important;
  // &:hover,
  // &:active,
  // &:focus {
  //   border-top: 0;
  //   border-left: 0;
  //   border-right: 0;
  //   border-bottom: 1px solid #ededed !important;
  // }
  --bs-btn-padding-x: 0 !important;
  --bs-btn-border-radius: 0 !important;
}
.sch-drpdwn-toggle svg {
  position: absolute;
  margin-top: 0;
  right: 10px;
  color: #007f00 !important;
}
// .dropdown-menu-sm .dropdown-item {
//   line-height: 0.846154 !important;
// }
// .dropdown-menu {
//   --bs-dropdown-border-width: 1px !important;
//   --bs-dropdown-divider-bg: rgba(0, 0, 0, 0.175) !important;
// }
.datepicker svg {
  right: 0 !important;
  margin-left: 81% !important;
}
.datepicker .flatpickr-wrapper input {
  padding: 0.4375rem 0 !important;
}
.filter_textfld {
  // border-top: transparent !important;
  // border-left: transparent !important;
  // border-right: transparent !important;
  // border-bottom: 1px solid #ededed !important;
  // &:hover,
  // &:active,
  // &:focus {
  //   border-top: transparent !important;
  //   border-left: transparent !important;
  //   border-right: transparent !important;
  //   border-bottom: 1px solid #ededed !important;
  // }
  padding: 0.3125rem 0 !important;
}
.flatpickr-wrapper input {
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  border-bottom: transparent !important;
  &:hover,
  &:active,
  &:focus {
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    border-bottom: transparent !important;
  }
}
// .react-table-sort {
//   display: none !important;
// }
.react-table-pagination {
  flex-direction: row-reverse !important;
}
.react-table-pagination .d-lg-block {
  display: none !important;
}
.react-table-pagination .justify-content-lg-start {
  justify-content: flex-end !important;
}
@media (min-width: 992px) {
  .react-table-pagination .d-lg-block {
    display: none !important;
  }
  .react-table-pagination .justify-content-lg-start {
    justify-content: flex-end !important;
  }
}
.pagination .active {
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.BusiChlcell {
  position: relative !important;
  white-space: break-spaces !important;
}
.BusiChlcell .dropdown {
  position: absolute !important;
  top: 0 !important;
  right: -20px !important;
}
.BusiChlcell .dropdown .btn {
  background: transparent !important;
  border: transparent !important;
  padding: 0 !important;
}
.BusiChlcell .dropdown .btn svg {
  color: $black !important;
}
.BusiChlcell .dropdown .dropdown-item {
  padding: 0px 10px !important;
  --bs-dropdown-min-width: 8rem !important;
}
.plc-color input::placeholder {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.datepicker input::placeholder {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.table-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown .disabled {
  background-color: transparent !important;
  border-color: transparent !important;
}
@media screen and (max-width: 576px) {
  .filter-text {
    text-align: center;
    font-size: 1rem !important;
  }
  .App {
    margin: 0;
  }
  .search-button {
    width: 45%;
  }
  .clear-button {
    width: 40%;
  }
  .filter-buttom-margin {
    margin-bottom: 2.5rem !important;
  }
  .download-all-badge {
    right: 30px !important;
    top: -20px !important;
  }
  .datepicker .flatpickr-wrapper input {
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    border-bottom: transparent !important;
    &:hover,
    &:active,
    &:focus {
      border-top: transparent !important;
      border-left: transparent !important;
      border-right: transparent !important;
      border-bottom: transparent !important;
    }
  }
  .ft-size {
    font-size: 0.6rem !important;
    --bs-btn-font-size: 0.6rem !important;
  }
  .datepicker .flatpickr-wrapper input {
    font-size: 0.6rem !important;
    --bs-btn-font-size: 0.6rem !important;
  }
  .datepicker .flatpickr-wrapper .numInputWrapper input {
    font-size: 1rem !important;
  }
  .no-Data-div {
    padding-top: 100px;
  }
}
.download-all-badge {
  right: 20px;
  top: -50px;
}
.filter-buttom-margin {
  margin-bottom: 1rem;
}
@media only screen and (max-width: 1200px) and (min-width: 576px) {
  .ft-size {
    font-size: 0.6rem !important;
    --bs-btn-font-size: 0.6rem !important;
  }
  .datepicker .flatpickr-wrapper input {
    font-size: 0.6rem !important;
    --bs-btn-font-size: 0.6rem !important;
  }
  .datepicker .flatpickr-wrapper .numInputWrapper input {
    font-size: 1rem !important;
  }
  .download-all-badge {
    right: 20px;
    top: -25px;
  }
  .md-margin {
    margin-top: 25px !important;
  }
}
.qik-srch-btn.disabled,
.qik-srch-btn:disabled {
  opacity: 0.5 !important;
}

@media only screen and (max-width: 780px) and (min-width: 576px) {
  .clear-button {
    margin-left: -15px;
  }
  .search-button {
    margin-right: -15px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .clear-button {
    margin-left: -15px;
  }
  .search-button {
    margin-right: -15px;
  }
}
.table-responsive {
  min-height: 200px !important;
}
