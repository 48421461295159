$dark-primary: #006600;
$primary: #007f00;
$secondary: #e4f1e5;
$white: #ffffff;
$black: #000000;
$vessel: #2b47e6;
$pipeline: #ff6600;
$draft: #616670;
.form-container {
  padding: 8px;
}

.App {
  display: flex;
  flex-direction: column;
  background: $white;
  margin: 2vh;
  padding: 15px;
}
.sch-drpdwn-toggle-uld {
  position: relative;
  text-align: start;
  //--bs-border-color: #dedede !important;
  //--bs-btn-padding-x: 0 !important;
  --bs-btn-border-radius: 0 !important;
}
.sch-drpdwn-toggle-uld svg {
  position: absolute;
  margin-top: 0;
  right: 10px;
}
.record-container {
  padding: 1rem;
  overflow-x: auto;
  gap: 20px;
  white-space: nowrap;
}
.upd-sch-btn.disabled,
.upd-sch-btn:disabled {
  opacity: 0.5 !important;
}
.datepicker1 svg {
  right: 0 !important;
  margin-left: 81% !important;
}
.datepicker1 input {
  height: 2.25rem !important;
  padding-left: 1rem !important;
  border: 1px solid #dedede !important;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid #dedede !important;
  }
}
.datepicker1 .numInputWrapper input {
  border: 0 !important;
  &:hover,
  &:active,
  &:focus {
    border: 0 !important;
  }
}
.docUld-file-name {
  display: block;
  border: 0 !important;
  padding: 10px;
  overflow: auto;
  text-wrap: wrap;
  font-size: 10px;
  word-wrap: break-word;
  text-align: start;
  line-height: 12px;
}
.docUld-text-icon {
  border: 1px solid #dedede !important;
  padding: 0.64rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-button1 {
  border-radius: 50px;
  width: 78px;
  text-align: center;
  min-width: fit-content;
}
.search-button1.disabled,
.search-button1:disabled {
  opacity: 0.5 !important;
}
.clear-button1 {
  color: $black;
  border-radius: 50px;
  background-color: $white !important;
  border-color: $primary !important;
  width: 78px;
  text-align: center;
  min-width: fit-content;
}
.drn-upload-modal {
  border-radius: 5px;
  max-width: 95%;
  box-shadow: 0px 5px 5px -2px grey;
}

.drn-upload-modal-header {
  border: 0;
}

.input-field-modal {
  width: 20%;
}
.inv_gr_doc {
  display: inline;
  text-wrap: nowrap !important;
}
.val_gr_doc {
  display: none;
}
.inv_doc {
  display: inline;
  text-wrap: nowrap !important;
}
.val_doc {
  display: none;
}
.inv_doc_size {
  display: inline;
  text-wrap: nowrap !important;
}
.val_doc_size {
  display: none;
}
.docName-field {
  text-wrap: nowrap !important;
}
// .upd-srch-drop {
//   //width: 20% !important;
// }
@media screen and (max-width: 576px) {
  .App {
    margin: 0;
  }
  .upd-srch-drop {
    width: 100% !important;
  }
  .ft-size {
    font-size: 0.6rem !important;
    --bs-btn-font-size: 0.6rem !important;
  }
  .datepicker1 .flatpickr-wrapper input {
    font-size: 0.6rem !important;
    --bs-btn-font-size: 0.6rem !important;
  }
  .datepicker1 .flatpickr-wrapper .numInputWrapper input {
    font-size: 1rem !important;
  }
}
@media screen and (max-width: 1200px) and (min-width: 576px) {
  .grid-col-scroll {
    display: grid !important;
    grid-gap: 16px !important;
    padding: 16px !important;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)) !important;
    grid-auto-flow: column !important;
    grid-auto-columns: minmax(200px, 1fr) !important;
    // overflow-x: auto !important;
    text-wrap: nowrap !important;
  }
  .width-fr-small {
    width: 180px !important;
  }
  .align-scroll {
    overflow-x: auto !important;
    padding-bottom: 1rem !important;
  }
  .left-margin-fr-md {
    margin-left: -8rem !important;
  }
  .ft-size {
    font-size: 0.6rem !important;
    --bs-btn-font-size: 0.6rem !important;
  }
  .datepicker1 .flatpickr-wrapper input {
    font-size: 0.6rem !important;
    --bs-btn-font-size: 0.6rem !important;
  }
  .datepicker1 .flatpickr-wrapper .numInputWrapper input {
    font-size: 1rem !important;
  }
  .txt-size {
    font-size: 0.6rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .txt-size {
    font-size: inherit !important;
  }
}
.flatpickr-wrapper input {
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  border-bottom: transparent !important;
  &:hover,
  &:active,
  &:focus {
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    border-bottom: transparent !important;
  }
}
.down-arrow-color {
  color: #007f00 !important;
}
@media screen and (min-width: 992px) {
  .left-pding-region {
    padding-left: 2rem !important;
  }
  .left-pding-country {
    padding-left: 4rem !important;
  }
  .left-pding-vessel {
    padding-left: 2rem !important;
  }

  .con-eles {
    line-height: 1.25 !important;
  }
}
@media screen and (max-width: 992px) {
  .txt-right {
    text-align: right;
  }
  .con-eles {
    margin-bottom: 1rem !important;
    line-height: 1.5 !important;
  }
  .prop-font {
    font-size: 0.9rem !important;
  }
  .value-font {
    font-size: 0.75rem !important;
  }
}
@media screen and (max-width: 576px) {
  .prop-font {
    font-size: 0.97rem !important;
  }
  .value-font {
    font-size: 0.79rem !important;
    text-wrap: wrap !important;
  }
}
@media screen and (min-width: 992px) {
  .prop-font {
    font-size: 1.1rem !important;
  }
  .value-font {
    font-size: 0.875rem !important;
  }
}

@media screen and (min-width: 576px){
  .w-20 {
    width: 20% !important;
  }
  .upd-form-ele-border {
    border: 0 !important;
  }
  .filter-btn-con {
    display: flex !important;
    flex-direction: row-reverse !important;
  }
  .search-button1 {
    margin: 0px 1.5rem!important
  }
}
@media screen and (max-width: 576px){
  .upd-form-ele-border {
    border: 1px solid #dedede !important;
  }
  .flatpickr-wrapper input {
    border: 1px solid #dedede !important;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid #dedede !important;
    }
  }
  .pding-fr-sm{
    padding: 0px 15px !important;
  }
  .filter-btn-con {
    display: flex !important;
    flex-direction: column !important;
  }
  .search-button1 {
    margin: 1rem 0 !important;
    width: 100% !important;
  }
  .clear-button1 {
    width: 100% !important;
  }
  .txt-size {
    font-size: 0.6rem !important;
  }
}

@media screen and (max-width: 360px) {
  .upd-file-text {
    width: 65% !important;
  }
  .upd-file-lable {
    width: 35% !important;
  }
}