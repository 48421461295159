.main-header-logo-img {
  margin-left: 75px;
  position: relative;
  z-index: 1111;
  height: 100%;
  width: 10%;
}
img {
  border: 0;
}
.main-header-logo-background {
  // -webkit-box-align: center;
  // -ms-flex-align: center;
  // align-items: center;
  background-color: #fff !important;
  //text-align: center;
  z-index: 2;

  //background: url("../../../public/images/castrol_header_pattern.png") repeat-x;
  background-size: auto 100%;
  min-height: 70px;
  padding: 7px 0;
  position: relative;
  width: 100%;
  //background-image: url("../../../public/images/castrol_header_pattern.png");
  background-position-x: initial;
  background-position-y: initial;
  background-position-x: repeat;
  background-position-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
}
@media screen and (min-width: 1024px) {
  .main-header-logo-background {
    display: block;
  }
}
.main-header-logo-background:after {
  //background: url("../../../public/images/header_bg_big.jpg") no-repeat -43px 0;
  background-size: auto 100%;
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  //background-image: url("../../../public/images/header_bg_big.jpg");
  background-position-x: 0px;
  background-position-y: 0px;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
}
.main-header-logo {
  z-index: 12;
  display: inline-block;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  height: 46px;
  position: relative;
}
.main-header-text {
  color: #fff;
  display: inline-block;
  margin-left: 35px;
  margin-top: 5px;
  display: none;
}
@media screen and (max-width: 576px) {
  .main-header-text {
    display: none;
  }
  .main-header-logo-img {
    position: relative;
    z-index: 1111;
    height: 55%;
    width: 15%;
    display: flex;
    margin: 15px 30px;
  }
  .avatar-label {
    display: none;
  }
}
.profile-field {
  position: absolute !important;
  right: 30px;
  top: 20px;
  z-index: 9999;
}
.avatar-inner {
  background-color: transparent !important;
}
.avatar-inner svg {
  //color: #fff !important;
  transform: translate(-50%, -50%) !important;
}
.avatar {
  //--avatar-label-color: #fff !important;
  //--avatar-hover-label-color: #fff !important;
  //--avatar-active-label-color: #fff !important;
}
.avatar-arrow {
  //color: #fff !important;
}
.landing-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}